import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";

const Header = () => {
  const [theme, setTheme] = useState("");
  const [lang, setLang] = useState("am");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <Navbar fluid rounded className="bg-cyan100 dark:bg-gray-900">
      <Navbar.Collapse>
        <Navbar.Link>
          <Link to="/">{t("Home")}</Link>
        </Navbar.Link>
        <Navbar.Link>
          <Link to="/about">{t("About")}</Link>
        </Navbar.Link>
        <Navbar.Link>
          <Link to="/contact">{t("Contact")}</Link>
        </Navbar.Link>
      </Navbar.Collapse>
      <div className="flex md:order-last">
        <Navbar.Toggle className="mr-2" />
        <button
          onClick={() => {
            setTheme(theme === "dark" ? "light" : "dark");
          }}
          className="mr-4"
        >
          {theme === "dark" ? (
            <svg
              className="h-8 w-8 text-black dark:text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="12" r="5" />{" "}
              <line x1="12" y1="1" x2="12" y2="3" />{" "}
              <line x1="12" y1="21" x2="12" y2="23" />{" "}
              <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />{" "}
              <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />{" "}
              <line x1="1" y1="12" x2="3" y2="12" />{" "}
              <line x1="21" y1="12" x2="23" y2="12" />{" "}
              <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />{" "}
              <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
            </svg>
          ) : (
            <svg
              className="h-8 w-8 text-black dark:text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
          )}
        </button>
        <button
          onClick={() => {
            setLang(lang === "en" ? "am" : "en");
          }}
          className="info mr-2"
        >
          {lang === "en" ? <>አማርኛ</> : <>English</>}
        </button>
      </div>
      <Navbar.Brand>
        <Link to="/">
          <img
            alt={t("NamrudLogo")}
            className="mr-3 h-6 sm:h-9 rounded-full inline"
            src={require("../assets/images/namrud.jpg")}
          />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            {t("Namrud")}
          </span>
        </Link>
      </Navbar.Brand>
    </Navbar>
  );
};

export default Header;
