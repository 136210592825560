import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-cyan50 dark:bg-gray-700">
      <Helmet>
        <title>{t("About")}</title>
      </Helmet>

      <div className="xl:px-28 px-3 text-center py-5">
        <span className="text-3xl underline">{t("OurHistory")}</span>
        <p className="text-left text-justify py-3">
          Available energy sources include potential energy (e.g. energy of the
          Earth's gravitational field as exploited in hydroelectric power
          generation), heat energy (e.g. geothermal), chemical energy, electric
          potential and nuclear energy (from nuclear fission or nuclear fusion).
          Many of these processes generate heat as an intermediate energy form,
          so heat engines have special importance. Some natural processes, such
          as atmospheric convection cells convert environmental heat into motion
          (e.g. in the form of rising air currents). Mechanical energy is of
          particular importance in transportation, but also plays a role in many
          industrial processes such as cutting, grinding, crushing, and mixing.
        </p>
        <p className="text-left text-justify">
          Available energy sources include potential energy (e.g. energy of the
          Earth's gravitational field as exploited in hydroelectric power
          generation), heat energy (e.g. geothermal), chemical energy, electric
          potential and nuclear energy (from nuclear fission or nuclear fusion).
          Many of these processes generate heat as an intermediate energy form,
          so heat engines have special importance. Some natural processes, such
          as atmospheric convection cells convert environmental heat into motion
          (e.g. in the form of rising air currents). Mechanical energy is of
          particular importance in transportation, but also plays a role in many
          industrial processes such as cutting, grinding, crushing, and mixing.
        </p>
      </div>
      <div className="text-center py-5 text-2xl">Us The Engineers.</div>
      <div className="grid grid-cols-3 xl:px-32 gap-4">
        <div className="text-center">
          <img
            src={require("../assets/images/2.jpg")}
            alt="Prof"
            className="mx-auto w-1/2"
          />
          <span className="">Chef Eng. Basnael Assefa</span>
        </div>
        <div className="text-center">
          <img
            src={require("../assets/images/2.jpg")}
            alt="Prof"
            className="mx-auto w-1/2"
          />
          <span className="">Chef Eng. Basnael Assefa</span>
        </div>
        <div className="text-center">
          <img
            src={require("../assets/images/2.jpg")}
            alt="Prof"
            className="mx-auto w-1/2"
          />
          <span className="">Chef Eng. Basnael Assefa</span>
        </div>
        <div className="text-center">
          <img
            src={require("../assets/images/2.jpg")}
            alt="Prof"
            className="mx-auto w-1/2"
          />
          <span className="">Chef Eng. Basnael Assefa</span>
        </div>
        <div className="text-center">
          <img
            src={require("../assets/images/2.jpg")}
            alt="Prof"
            className="mx-auto w-1/2"
          />
          <span className="">Chef Eng. Basnael Assefa</span>
        </div>
        <div className="text-center">
          <img
            src={require("../assets/images/2.jpg")}
            alt="Prof"
            className="mx-auto w-1/2"
          />
          <span className="">Chef Eng. Basnael Assefa</span>
        </div>
      </div>
      <div className="xl:px-28 px-2 py-5 h-72">
        <img
          src={require("../assets/images/namrud-grid.jpg")}
          alt=""
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

export default About;
