import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Home: "Home",
      Namrud: "Namrud",
      NamrudLogo: "Namrud Logo",
      About: "About",
      Contact: "Contact",
      AllRightResereved: "All Rights Reserved.",
      DeveloperContact: "Developer Contact",
      ContactUs: "Contact Us",
      GetInTouch: "Get In Touch With Us",
      OurLocation: "Our Location",
      PhoneNumber: "Phone Number",
      EmailAddress: "Email Address",
      Name: "Your Name",
      Email: "Your Email",
      Phone: "Your Phone",
      Message: "Your Message",
      SendMessage: "Send Message",
      ClothesShop: "Traditional Clothes Selling Shop",
      PageNotFound: "Page Not Found",
      ooops: "Ooops ...",
      PageNotFoundMessage: "Sorry, This page doesn't exist!",
      OurServices: "Our Services",
      OurHistory: "Our History",
    },
  },
  am: {
    translation: {
      OurHistory: "ታሪካችን",
      OurServices: "አገልግሎታችን",
      PageNotFound: "ገፁ አልተገኘም",
      ooops: "ውይ ...",
      PageNotFoundMessage: "ይቅርታ ፤ ገፁ አልተገኘም",
      Home: "መነሻ ገፅ",
      Namrud: "ናምሩድ",
      NamrudLogo: "የናምሩድ መለያ",
      About: "ስለ",
      Contact: "ያግኙን",
      AllRightResereved: "መብቱ በሕግ የተጠበቀ።",
      DeveloperContact: "የአበልፃጊው መገኛ",
      ContactUs: "ያግኙን",
      GetInTouch: "ከእኛ ጋር ይገናኙ",
      OurLocation: "አድራሻችን",
      PhoneNumber: "ስልክ ቁጥር",
      EmailAddress: "የፈጣን መልዕክት አድራሻ",
      Name: "ስም",
      Email: "ፈጣን መልዕክት",
      Phone: "ስልክ",
      Message: "መልዕክት",
      ClothesShop: "ባህላዊ ልብሶች መሽጫ መደብር",
      SendMessage: "ይላኩ",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
