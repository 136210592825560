import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Slides from "../components/Slides";
import { Link } from "react-router-dom";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-cyan50 dark:bg-gray-700">
      <Helmet>
        <title>{t("Home")}</title>
      </Helmet>
      <div className="h-64 xl:px-24 xl:h-screen">
        <Slides />
      </div>
      <span className="text-5xl underline flex flex-col items-center py-5">
        {t("OurServices")}
      </span>
      <div className="grid grid-cols-2 px-10 gap-4">
        <div className="lg:flex gap-3">
          <img
            src={require("../assets/images/3.jpg")}
            alt=""
            className="h-48 w-56"
          />
          <article className="text-wrap">
            <span className="font-bold text-lg">Engine Problem</span>
            <p className="indent-5 text-center lg:text-justify xl:text-justify md:text-justify">
              Available energy sources include potential energy (e.g. energy of
              the Earth's gravitational field as exploited in hydroelectric
              power generation), heat energy (e.g. geothermal), chemical energy,
              electric potential and nuclear energy (from nuclear fission or
              nuclear fusion).
            </p>
          </article>
        </div>
        <div className="lg:flex gap-3">
          <img
            src={require("../assets/images/3.jpg")}
            alt=""
            className="w-56 h-48"
          />
          <article className="text-wrap">
            <span className="font-bold text-lg">Engine Problem</span>
            <p className="indent-5 text-center lg:text-justify xl:text-justify md:text-justify">
              Many of these processes generate heat as an intermediate energy
              form, so heat engines have special importance. Some natural
              processes, such as atmospheric convection cells convert
              environmental heat into motion (e.g. in the form of rising air
              currents). Mechanical energy is of particular importance in
              transportation, but also plays a role in many industrial processes
              such as cutting, grinding, crushing, and mixing.
            </p>
          </article>
        </div>
        <div className="lg:flex gap-3">
          <img
            src={require("../assets/images/3.jpg")}
            alt=""
            className="w-56 h-48"
          />
          <article className="text-wrap">
            <span className="font-bold text-lg">Engine Problem</span>
            <p className="indent-5 text-center lg:text-justify xl:text-justify md:text-justify">
              Available energy sources include potential energy (e.g. energy of
              the Earth's gravitational field as exploited in hydroelectric
              power generation), heat energy (e.g. geothermal), chemical energy,
              electric potential and nuclear energy (from nuclear fission or
              nuclear fusion).
            </p>
          </article>
        </div>
        <div className="lg:flex gap-3">
          <img
            src={require("../assets/images/3.jpg")}
            alt=""
            className="w-56 h-48"
          />
          <article className="text-wrap">
            <span className="font-bold text-lg">Engine Problem</span>
            <p className="indent-5 text-center lg:text-justify xl:text-justify md:text-justify">
              Many of these processes generate heat as an intermediate energy
              form, so heat engines have special importance. Some natural
              processes, such as atmospheric convection cells convert
              environmental heat into motion (e.g. in the form of rising air
              currents). Mechanical energy is of particular importance in
              transportation, but also plays a role in many industrial processes
              such as cutting, grinding, crushing, and mixing.
            </p>
          </article>
        </div>
      </div>
      <span className="text-5xl underline flex flex-col items-center py-5">
        Our History
      </span>
      <div className="grid grid-cols-1 lg:px-48 py-3 text-justify">
        <p className="shadow-indigo-200 shadow-lg py-2 rounded-2xl px-4 font-serif">
          Available energy sources include potential energy (e.g. energy of the
          Earth's gravitational field as exploited in hydroelectric power
          generation), heat energy (e.g. geothermal), chemical energy, electric
          potential and nuclear energy (from nuclear fission or nuclear
          fusion).Many of these processes generate heat as an intermediate
          energy form, so heat engines have special importance. Some natural
          processes, such as atmospheric convection cells convert environmental
          heat into motion (e.g. in the form of rising air currents). Mechanical
          energy is of particular importance in transportation, but also plays a
          role in many industrial processes such as cutting, grinding, crushing,
          and mixing.{" "}
          <Link to="about/" className="hover:underline font-bold">
            See more...
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Home;
