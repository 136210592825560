import React from "react";
import Header from "../components/Header";
import Fotter from "../components/Fotter";

const Layout = ({ children }: any) => {
  return (
    <div className="h-screen dark:bg-slate-800 dark:text-white">
      <Header />
      {children}
      <Fotter />
    </div>
  );
};

export default Layout;
