import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from './layout/Layout';
import "./services/i18n";
import Home from "./pages/Home";
import Contact from './pages/Contact';
import About from './pages/About';
import NotFound from './pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<About />} path="/about" />
          <Route element={<NotFound />} path="*" />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
